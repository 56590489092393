export const navbarLinks = [
  { name: 'Home', href: '/' },

  {
    name: 'About',
    href: '/about',
  },
  {
    name: 'Pricing',
    href: '/pricing',
  },
  {
    name: 'Resources',
    href: '/resources',
  },
  {
    name: 'Blog',
    href: '/blog',
  },
  {
    name: 'Support',
    href: '/support',
  },
];
