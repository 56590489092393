'use client';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select';
import { cn } from '@/utils/cn';
import acmeLightLogo from '@public/logos/acme-logo-dark.png';
import acmeDarkLogo from '@public/logos/acme-logo-light.png';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { DocsMobileNavigation } from './DocsMobileNavigation';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import LaptopIcon from 'lucide-react/dist/esm/icons/laptop';
import MoonIcon from 'lucide-react/dist/esm/icons/moon';
import LightIcon from 'lucide-react/dist/esm/icons/sun';
import { navbarLinks } from './constants';
const versions = [
  { label: 'Landing page 1', url: '/' },
  { label: 'Landing page 2', url: '/version2' },
];

export function VersionSwitcher({
  selectedVersion,
  setSelectedVersion,
}: {
  selectedVersion: string;
  setSelectedVersion: (versionUrl: string) => void;
}) {
  const router = useRouter();

  const handleSelect = (versionUrl) => {
    setSelectedVersion(versionUrl);
    router.refresh();
    router.push(versionUrl);
  };

  return (
    <>
      {/* <Select onValueChange={handleSelect}>
        <SelectTrigger className="mt-0.5 sm:w-[96px] md:w-[200px]">
          {versions.find((version) => version.url === selectedVersion)?.label ||
            'Select Landing Page'}
        </SelectTrigger>

        <SelectContent className="sm:w-[80px] md:w-[200px]">
          {versions.map((version) => (
            <SelectItem key={version.url} value={version.url}>
              {version.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select> */}
    </>
  );
}

export function LeftNav() {
  const pathname = usePathname();
  const initialVersion =
    versions.find((version) => version.url === pathname)?.url ||
    versions[0].url;
  const [selectedVersion, setSelectedVersion] = useState(initialVersion);
  const isALandingPage = pathname
    ? pathname.startsWith('/version') || pathname === '/'
    : false;

  useEffect(() => {
    const newSelectedVersion =
      versions.find((version) => version.url === pathname)?.url ||
      versions[0].url;
    setSelectedVersion(newSelectedVersion);
  }, [pathname]);
  const isActive = pathname;
  const router = useRouter();
  
  return (
    <>
      <DocsMobileNavigation />
      <div className="flex space-x-8">
        <Link href="/" className={cn('font-bold text-xl ')}>
          <div className="relative flex space-x-2 h-10 md:w-fit items-center justify-center text-black dark:text-white dark:-ml-4 -ml-2">
            <Image
              src={acmeLightLogo}
              width={40}
              height={40}
              alt="logo"
              className="dark:hidden block h-8 w-8"
            />
            <Image
              src={acmeDarkLogo}
              width={40}
              height={40}
              alt="logo"
              className="hidden dark:block h-8 w-8"
            />
            <span className="lg:hidden font-bold ">Everything Talent</span>
            <span className="hidden font-bold lg:inline-block">Everything Talent</span>
          </div>
        </Link>

        {isALandingPage && (
          <VersionSwitcher
            selectedVersion={selectedVersion}
            setSelectedVersion={setSelectedVersion}
          />
        )}
      </div>
      {
        <ul className="hidden  lg:flex gap-8 font-medium items-center">
          {navbarLinks.slice(0,2).map(({ name, href }) => (
            <li
              key={name}
              className={cn("font-regular  text-gray-600 dark:hover:text-slate-300 dark:text-slate-400 text-sm hover:text-gray-800 ",
                isActive===href && "dark:text-white text-gray-950"
              )}
            >
              <Link href={href}>{name}</Link>
            </li>
          ))}
          {/* new use-cases */}
          <DropdownMenu>
              <DropdownMenuTrigger
                asChild
              >
                <li
                  className={cn("font-regular cursor-pointer text-gray-600 dark:hover:text-slate-300 dark:text-slate-400 text-sm hover:text-gray-800 ",
                    pathname === ('/use-cases/funded-startups') && "dark:text-white text-gray-950",
                    pathname === ('/use-cases/hiring-solutions') && "dark:text-white text-gray-950"
                  )}
                >
                  Use Cases
                </li>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="dark:bg-black">
              <Link href='/use-cases/hiring-solutions'>
                <DropdownMenuItem onClick={() => router.push('/use-cases/hiring-solutions')}
                   className={cn("font-regular  text-gray-600 dark:hover:text-slate-300 dark:text-slate-400 text-sm hover:text-gray-800 ",
                    pathname === ('/use-cases/hiring-solutions') && "dark:text-white text-gray-950"
                  )}>
                  Hiring Solutions         
                </DropdownMenuItem>
                </Link>
                <Link href='/use-cases/funded-startups'>
                <DropdownMenuItem onClick={() => router.push('/use-cases/funded-startups')}  
                className={cn("font-regular  text-gray-600 dark:hover:text-slate-300 dark:text-slate-400 text-sm hover:text-gray-800 ",
                    pathname === ('/use-cases/funded-startups') && "dark:text-white text-gray-950",
                  )}>
                  Funded Startups
                </DropdownMenuItem>
                </Link>
              </DropdownMenuContent>
            </DropdownMenu>
            {navbarLinks.slice(2).map(({ name, href }) => (
            <li
              key={name}
              className={cn("font-regular  text-gray-600 dark:hover:text-slate-300 dark:text-slate-400 text-sm hover:text-gray-800 ",
                isActive===href && "dark:text-white text-gray-950"
              )}
            >
              <Link href={href}>{name}</Link>
            </li>
          ))}
        </ul>
      }
    </>
  );
}
